import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Navigation from "../components/navigation"
import "./header.css"

const nav = [
  {
    link: "/manifest",
    label: "Manifest"
  },
  {
    link: "/o-meni",
    label: "Ja u svijetu"
  },
  {
    link: "/svjetovi",
    label: "Svjetovi u meni"
  },
  {
    link: "/invokacija",
    label: "Invokacija"
  }
]

const Header = ({ siteTitle }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
  <header>
    <div className="header__wrapper">
      <h1 className="header__title">
        <Link className="header__link" to="/">
          {siteTitle}
        </Link>
      </h1>
        <Navigation navElements={nav} menuVisible={menuVisible} />
        <div class={"hamburger" + (menuVisible ? ' is-active' : '')} onClick={e => setMenuVisible(!menuVisible)}></div>
    </div>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
