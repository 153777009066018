import React from "react"
import { Link } from "gatsby"
import "./navigation.css"

export default ({ navElements, menuVisible = false }) => (
    <nav className={"nav__wrapper" + (menuVisible ? ' is-active' : '')}>
        {navElements.map((element, index) =>
            <Link key={index} className="nav__link" to={element.link}>{element.label}</Link>
        )}
    </nav>
)